import React, { lazy } from "react";
import LoginPage from "./pages/login";
import { Redirect } from "react-router-dom";
import UpdatePasswordPage from "./pages/update-password";
import ProfilePage from "./pages/profile";
import { userRoles } from "./constants";
import CreativeGroupPage from "./pages/public/creative-group";
import UnauthorizedPage from "./pages/unauthorized";
import ClientPage from "./pages/public/client";
import LeadCampaignPage from "./pages/public/lead-campaign";

let CreativeGroupEdit = lazy(() =>
  import("./packages/brain-creative/pages/creative-group-edit"),
);

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/",
    name: "home",
    exact: true,
    isPrivate: true,
    iconType: "app",
    menu: false,
    label: "Home",
    title: "Home",
    subtitle: "Dashboard Homepage",
    component: () => <Redirect to="/stats/general" />,
  },
  {
    path: "/abtests/create",
    name: "create-abtest",
    exact: true,
    isPrivate: true,
    iconType: "app",
    menu: "abtest",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    label: "Create",
    title: "Create A/B Test",
    subtitle: "Create A/B",
    component: lazy(() =>
      import("./packages/brain-abtest/pages/components/create"),
    ),
  },
  {
    path: "/abtests/:id",
    name: "edit-abtest",
    exact: true,
    isPrivate: true,
    iconType: "app",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    label: "Modifica  A/B Test",
    title: "Edit A/B Test",
    subtitle: "Edit A/B",
    component: lazy(() =>
      import("./packages/brain-abtest/pages/components/edit"),
    ),
  },
  {
    path: "/abtests",
    name: "abtest",
    exact: true,
    isPrivate: true,
    iconType: "app",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    menu: "abtest",
    label: "List",
    title: "A/B Test",
    subtitle: "A/B test lists",
    component: lazy(() => import("./packages/brain-abtest/pages/components")),
  },
  {
    path: "/creative-groups/create",
    name: "creative-group-create",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Create",
    title: "Create creative group",
    subtitle: "creative group create",
    component: () => <CreativeGroupEdit mode="create" />,
  },
  {
    path: "/creative-groups/:id/edit",
    name: "creative-group-edit",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Edit",
    title: "Edit creative group",
    subtitle: "creative group edit",
    component: () => <CreativeGroupEdit mode="edit" />,
  },
  {
    path: "/creative-groups",
    name: "creative-groups",
    menu: "creative",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Groups",
    title: "Creative groups",
    subtitle: "creative list",
    component: lazy(() =>
      import("./packages/brain-creative/pages/creative-groups"),
    ),
  },
  {
    path: "/creative-tasks/create",
    name: "creative-tasks-create",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Create creative task",
    title: "Create creative task",
    subtitle: "Create creative task",
    component: () => <CreativeGroupEdit mode="create" type="task" />,
  },
  {
    path: "/creative-tasks/:id/edit",
    name: "creative-group-edit",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Edit creative task",
    title: "Edit creative task",
    subtitle: "Edit creative task",
    component: () => <CreativeGroupEdit mode="edit" type="task" />,
  },
  {
    path: "/creative-tasks",
    name: "creative-group-tasks",
    exact: true,
    menu: "creative",
    isPrivate: true,
    iconType: "grid",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Tasks",
    title: "Tasks",
    subtitle: "Tasks",
    component: lazy(() => import("./packages/brain-creative/pages/tasks")),
  },
  {
    path: "/campaigns/reservations/create",
    name: "reservations-create",
    exact: true,
    isPrivate: true,
    iconType: "accessibility",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    label: "Create reservation",
    title: "Create reservation",
    subtitle: "reservations create",
    component: lazy(() =>
      import(
        "./packages/brain-campaign/pages/reservations/reservations-create"
      ),
    ),
  },
  {
    path: "/campaigns/reservations/:id",
    name: "reservations-edit",
    exact: true,
    isPrivate: true,
    iconType: "accessibility",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    label: "Edit reservation",
    title: "Edit reservation",
    subtitle: "reservations edit",
    component: lazy(() =>
      import("./packages/brain-campaign/pages/reservations/reservations-edit"),
    ),
  },
  {
    path: "/campaigns/reservations",
    name: "reservations-campaign",
    exact: true,
    isPrivate: true,
    menu: "campaign",
    iconType: "accessibility",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    label: "Reservations",
    title: "Reservations",
    subtitle: "reservations campaign",
    component: lazy(() =>
      import("./packages/brain-campaign/pages/reservations/reservations-list"),
    ),
  },
  {
    path: "/campaigns/payouts",
    name: "payouts-campaign",
    exact: true,
    isPrivate: true,
    menu: "campaign",
    iconType: "accessibility",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    label: "Payout",
    title: "Payout",
    subtitle: "payout campaign",
    component: lazy(() => import("./packages/brain-campaign/pages/payout")),
  },
  {
    path: "/campaigns/create",
    name: "create-campaign",
    exact: true,
    isPrivate: true,
    menu: "campaign",
    iconType: "accessibility",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Create",
    title: "Create campaign",
    subtitle: "Create campaign",
    component: lazy(() =>
      import("./packages/brain-campaign/pages/campaigns/components/create"),
    ),
  },
  {
    path: "/campaigns/:id/bi",
    name: "bi-campaign",
    exact: true,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    label: "Business intellgence campagna",
    title: "BI Campaigns",
    subtitle: "",
    component: lazy(() =>
      import(
        "./packages/brain-campaign/pages/business-intelligence/components"
      ),
    ),
  },
  {
    path: "/campaigns/:id/details",
    name: "details-campaign",
    exact: true,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Dettagli campagna",
    title: "Campaign details",
    subtitle: "Explore campaign details",
    component: lazy(() =>
      import("./packages/brain-campaign/pages/campaigns/components/details"),
    ),
  },
  {
    path: "/campaigns/:id",
    name: "edit-campaign",
    exact: true,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Edit campaign",
    title: "Edit campaign",
    subtitle: "Edit campaign",
    component: lazy(() =>
      import("./packages/brain-campaign/pages/campaigns/components/edit"),
    ),
  },
  {
    path: "/campaigns",
    name: "campaigns",
    menu: "campaign",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "List",
    title: "Campaigns",
    subtitle: "campaign list",
    component: lazy(() => import("./packages/brain-campaign/pages/campaigns")),
  },
  {
    path: "/clients/create",
    name: "create-client",
    exact: true,
    menu: "client",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    isPrivate: true,
    iconType: "accessibility",
    label: "Create",
    title: "Create client",
    subtitle: "Create client",
    component: lazy(() =>
      import("./packages/brain-client/pages/clients/components/create"),
    ),
  },
  {
    path: "/clients/:id",
    name: "edit-client",
    exact: false,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    label: "Edit client",
    title: "Edit client",
    subtitle: "Edit client",
    component: lazy(() =>
      import("./packages/brain-client/pages/clients/components/edit"),
    ),
  },
  {
    path: "/clients",
    name: "clients",
    exact: true,
    menu: "client",
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    iconType: "metricsApp",
    label: "List",
    title: "Clients",
    subtitle: "Manage  clients",
    component: lazy(() => import("./packages/brain-client/pages/clients")),
  },
  {
    path: "/funnels/create",
    name: "create-funnel",
    exact: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    isPrivate: true,
    iconType: "accessibility",
    label: "Create",
    title: "Create funnel",
    subtitle: "Create funnel",
    component: lazy(() =>
      import("./packages/brain-campaign/pages/funnels/pages/create"),
    ),
  },
  {
    path: "/funnels/:id",
    name: "edit-funnel",
    exact: false,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Edit funnel",
    title: "Edit funnel",
    subtitle: "Edit funnel",
    component: lazy(() =>
      import("./packages/brain-campaign/pages/funnels/pages/edit"),
    ),
  },
  {
    path: "/funnels",
    name: "funnels",
    exact: true,
    menu: "campaign",
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "metricsApp",
    label: "Funnels",
    title: "Funnels",
    subtitle: "Manage  funnels",
    component: lazy(() =>
      import("./packages/brain-campaign/pages/funnels/pages"),
    ),
  },
  {
    path: "/postbacks",
    name: "postbacks",
    exact: true,
    menu: "campaign",
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "metricsApp",
    label: "Postbacks",
    title: "Postbacks",
    subtitle: "Manage  postbacks",
    component: lazy(() =>
      import("./packages/brain-campaign/pages/postbacks/pages"),
    ),
  },
  {
    path: "/postbacks/create",
    name: "create-postback",
    exact: true,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Create",
    title: "Create postback",
    subtitle: "Create postback",
    component: lazy(() =>
      import("./packages/brain-campaign/pages/postbacks/pages/create"),
    ),
  },
  {
    path: "/postbacks/:id",
    name: "postbacks-edit",
    exact: false,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "logoUptime",
    label: "Modifica",
    title: "Edit Postback",
    subtitle: "Edit Postback",
    component: lazy(() =>
      import("./packages/brain-campaign/pages/postbacks/pages/edit"),
    ),
  },
  {
    path: "/magnum-bi/create",
    name: "magnum-bi-create",
    exact: true,
    menu: "magnum-bi",
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    iconType: "logoUptime",
    label: "Create",
    title: "Create BI report",
    subtitle: "Create BI report",
    component: lazy(() => import("./packages/brain-magnum-bi/pages/create")),
  },
  {
    path: "/magnum-bi",
    name: "magnum-bi",
    exact: true,
    menu: "magnum-bi",
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    iconType: "logoUptime",
    label: "List",
    title: "Magnum BI",
    subtitle: "Magnum BI",
    component: lazy(() => import("./packages/brain-magnum-bi/pages")),
  },
  {
    path: "/magnum-bi/:id/show",
    name: "show-report",
    exact: false,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    label: "BI report",
    title: "BI report",
    subtitle: "Show bi report",
    component: lazy(() => import("./packages/brain-magnum-bi/pages/show")),
  },
  {
    path: "/magnum-bi/:id",
    name: "magnum-bi-edit",
    exact: false,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    iconType: "logoUptime",
    label: "Modifica",
    title: "Edit BI report",
    subtitle: "Edit BI report",
    component: lazy(() => import("./packages/brain-magnum-bi/pages/edit")),
  },
  {
    path: "/merlin/create",
    name: "merlin-create",
    exact: true,
    menu: "merlin",
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "metricsApp",
    label: "Create",
    title: "Create Merlin rule",
    subtitle: "Create  merlin rules",
    component: lazy(() => import("./packages/brain-merlin/pages/create")),
  },
  {
    path: "/merlin/:id",
    name: "edit-rule",
    exact: false,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Edit merlin rule",
    title: "Edit merlin rule",
    subtitle: "Edit merlin",
    component: lazy(() => import("./packages/brain-merlin/pages/edit")),
  },
  {
    path: "/merlin",
    name: "merlin",
    exact: true,
    menu: "merlin",
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "metricsApp",
    label: "List",
    title: "Merlin",
    subtitle: "Manage  merlin rules",
    component: lazy(() => import("./packages/brain-merlin/pages")),
  },
  {
    path: "/stats/general",
    name: "stats",
    menu: "stats",
    exact: true,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.CLIENT]: true,
      [userRoles.FULL]: true,
      [userRoles.STATS]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "accessibility",
    label: "General Stats",
    title: "General Stats",
    subtitle: "Show general stats",
    component: lazy(() => import("./packages/brain-stats/pages")),
  },
  {
    path: "/leads/summary",
    name: "stats",
    exact: true,
    isPrivate: true,
    iconType: "accessibility",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
    },
    label: "Lead summary",
    title: "Lead summary",
    subtitle: "Show lead summary",
    component: lazy(() =>
      import("./packages/brain-lead/pages/leads/components/summary"),
    ),
  },
  {
    path: "/leads/:id",
    name: "show-lead",
    exact: false,
    isPrivate: true,
    iconType: "accessibility",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.CLIENT]: true,
    },
    label: "Lead details",
    title: "Lead details",
    subtitle: "Show lead",
    component: lazy(() =>
      import("./packages/brain-lead/pages/leads/components/lead"),
    ),
  },
  {
    path: "/leads",
    name: "leads",
    menu: "lead",
    exact: true,
    isPrivate: true,
    iconType: "accessibility",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.CLIENT]: true,
    },
    label: "List",
    title: "Leads",
    subtitle: "Show leads",
    component: lazy(() =>
      import("./packages/brain-lead/pages/leads/components"),
    ),
  },
  {
    path: "/chatbots/create",
    name: "create-chatbot",
    menu: "chatbot",
    exact: true,
    isPrivate: true,
    iconType: "tableOfContents",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Create",
    title: "Create chatbot",
    subtitle: "Create new chatbot",
    component: lazy(() => import("./packages/brain-chatbot/pages/create")),
  },
  {
    path: "/chatbots/:id/analytics",
    name: "analytics-chatbot",
    exact: false,
    isPrivate: true,
    iconType: "tableOfContents",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Analytics Chatbot",
    title: "Analytics Chatbot",
    subtitle: "Analytics chatbot",
    component: lazy(() => import("./packages/brain-chatbot/pages/analytics")),
  },
  {
    path: "/chatbots/:id",
    name: "edit-chatbot",
    exact: false,
    isPrivate: true,
    iconType: "tableOfContents",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Edit Chatbot",
    title: "Edit Chatbot",
    subtitle: "Edit chatbot",
    component: lazy(() => import("./packages/brain-chatbot/pages/edit")),
  },
  {
    path: "/chatbots",
    name: "chatbot",
    menu: "chatbot",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "List",
    title: "Chatbot",
    subtitle: "List of all chatbots",
    component: lazy(() => import("./packages/brain-chatbot/pages")),
  },
  {
    path: "/facebook/accounts",
    name: "facebook-accounts",
    exact: true,
    menu: "facebook",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    isPrivate: true,
    label: "Ad Accounts",
    title: "Facebook accounts",
    subtitle: "Facebook account list",
    component: lazy(() =>
      import("./packages/brain-facebook/pages/ad-accounts"),
    ),
  },
  {
    path: "/facebook/pages",
    name: "facebook-pages",
    exact: true,
    menu: "facebook",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    isPrivate: true,
    label: "Pages",
    title: "Facebook pages",
    subtitle: "Facebook pages list",
    component: lazy(() =>
      import("./packages/brain-facebook/pages/facebook-pages"),
    ),
  },
  {
    path: "/facebook/billing",
    name: "facebook-billings",
    exact: true,
    isPrivate: true,
    menu: "facebook",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    label: "Billing",
    title: "Facebook billings",
    subtitle: "Facebook billings list",
    component: lazy(() => import("./packages/brain-facebook/pages/billings")),
  },
  {
    path: "/facebook/campaigns",
    name: "facebook-campaigns",
    exact: true,
    isPrivate: true,
    menu: "facebook",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Campaigns",
    title: "Facebook campaigns",
    subtitle: "Facebook campaigns",
    component: lazy(() => import("./packages/brain-facebook/pages/campaigns")),
  },
  {
    path: "/facebook/instant-forms",
    name: "facebook-instant-forms",
    headerUrl: "/facebook/instant-forms",
    exact: true,
    isPrivate: true,
    menu: "facebook",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Instant Forms",
    title: "Facebook Instant Forms",
    subtitle: "Facebook Instant Forms",
    component: lazy(() =>
      import(
        "./packages/brain-facebook/pages/instant-forms/instant-forms-list"
      ),
    ),
  },
  {
    path: "/facebook/instant-forms/create",
    name: "facebook-instant-forms-create",
    headerUrl: "/facebook/instant-forms",
    exact: true,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Instant Forms",
    title: "Facebook Instant Forms",
    subtitle: "Facebook Instant Forms",
    component: lazy(() =>
      import(
        "./packages/brain-facebook/pages/instant-forms/instant-forms-create"
      ),
    ),
  },
  {
    path: "/facebook/instant-forms/:id",
    name: "facebook-instant-forms-edit",
    headerUrl: "/facebook/instant-forms",
    exact: true,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Instant Forms",
    title: "Facebook Instant Forms",
    subtitle: "Facebook Instant Forms",
    component: lazy(() =>
      import(
        "./packages/brain-facebook/pages/instant-forms/instant-forms-edit"
      ),
    ),
  },
  {
    path: "/facebook/traffic-sources",
    name: "facebook-traffic-sources",
    exact: true,
    isPrivate: true,
    menu: "facebook",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Traffic sources",
    title: "Facebook traffic sources",
    subtitle: "Facebook traffic sources",
    component: lazy(() =>
      import("./packages/brain-facebook/pages/traffic-sources"),
    ),
  },
  {
    path: "/facebook/operations/:id",
    name: "facebook-operations-details",
    exact: true,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    label: "Operation details",
    title: "Facebook operation details",
    subtitle: "Facebook operation details",
    component: lazy(() =>
      import("./packages/brain-facebook/pages/operations/components/details"),
    ),
  },
  {
    path: "/facebook/operations",
    name: "facebook-operations",
    exact: true,
    isPrivate: true,
    menu: "facebook",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    label: "Operations",
    title: "Facebook operations",
    subtitle: "Facebook operations",
    component: lazy(() =>
      import("./packages/brain-facebook/pages/operations/components/table"),
    ),
  },
  {
    path: "/adverstories/create",
    name: "create-adverstory",
    menu: "adverstory",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Create",
    title: "Create adverstory",
    subtitle: "Create new adverstory",
    component: lazy(() => import("./packages/brain-adverstory/pages/create")),
  },
  {
    path: "/adverstories/:id",
    name: "edit-adverstory",
    exact: false,
    isPrivate: true,
    iconType: "tableOfContents",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Modifica Adverstory",
    title: "Edit adverstory",
    subtitle: "Edit Adverstory",
    component: lazy(() => import("./packages/brain-adverstory/pages/edit")),
  },
  {
    path: "/adverstories",
    name: "adverstories",
    menu: "adverstory",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "List",
    title: "Adverstories",
    subtitle: "List of all adverstories",
    component: lazy(() => import("./packages/brain-adverstory/pages")),
  },
  {
    path: "/wizards/create",
    name: "create-potter",
    menu: "potter",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Create",
    title: "Create wizard",
    subtitle: "Create new wizard",
    component: lazy(() => import("./packages/brain-potter/pages/create")),
  },
  {
    path: "/wizards/:id/analytics",
    name: "analytics-potter",
    exact: false,
    isPrivate: true,
    iconType: "tableOfContents",
    label: "Analytics wizard",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    title: "Analytics wizard",
    subtitle: "Analytics wizard",
    component: lazy(() => import("./packages/brain-potter/pages/analytics")),
  },
  {
    path: "/wizards/:id",
    name: "edit-potter",
    exact: false,
    isPrivate: true,
    iconType: "tableOfContents",
    label: "Modifica wizard",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    title: "Edit wizard",
    subtitle: "Edit wizard",
    component: lazy(() => import("./packages/brain-potter/pages/edit")),
  },
  {
    path: "/wizards",
    name: "potters",
    menu: "potter",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    label: "List",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    title: "Wizard",
    subtitle: "List of all wizards",
    component: lazy(() => import("./packages/brain-potter/pages")),
  },
  {
    path: "/snitch",
    name: "snitch",
    menu: "snitch",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    label: "List",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    title: "Snitch",
    subtitle: "List of all Snitches",
    component: lazy(() => import("./packages/brain-snitch/pages")),
  },
  {
    path: "/snitch/:id",
    name: "snitch",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    label: "View",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    title: "View snitch",
    subtitle: "View Snitch",
    component: lazy(() => import("./packages/brain-snitch/pages/view")),
  },
  {
    path: "/amalia",
    name: "amalia",
    menu: "amalia",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    label: "List",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    title: "Amalia",
    subtitle: "List of all Amalias",
    component: lazy(() => import("./packages/brain-amalia/pages")),
  },
  {
    path: "/amalia/create",
    name: "edit-amalia",
    menu: "amalia",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    label: "Create",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    title: "Create amalia",
    subtitle: "Create Amalia",
    component: lazy(() => import("./packages/brain-amalia/pages/create")),
  },
  {
    path: "/amalia/:id",
    name: "edit-amalia",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    label: "List",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    title: "Edit",
    subtitle: "Edit Amalia",
    component: lazy(() => import("./packages/brain-amalia/pages/edit")),
  },
  {
    path: "/einstein/create",
    name: "create-smart-link",
    exact: true,
    isPrivate: true,
    iconType: "app",
    menu: "einstein",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Create",
    title: "Create smart link",
    subtitle: "Create smart-link",
    component: lazy(() => import("./packages/brain-smart-link/pages/create")),
  },
  {
    path: "/einstein/:id",
    name: "edit-smart-link",
    exact: true,
    isPrivate: true,
    iconType: "app",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Edit smart link",
    title: "Edit smart link",
    subtitle: "Edit smart link",
    component: lazy(() => import("./packages/brain-smart-link/pages/edit")),
  },
  {
    path: "/einstein",
    name: "smart-link",
    exact: true,
    isPrivate: true,
    iconType: "app",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    menu: "einstein",
    label: "List",
    title: "Einstein",
    subtitle: "smart-links list",
    component: lazy(() => import("./packages/brain-smart-link/pages")),
  },
  {
    path: "/tools/domains",
    name: "domains",
    menu: "tools",
    exact: true,
    isPrivate: true,
    iconType: "grid",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
    },
    label: "Domains",
    title: "Domains",
    subtitle: "Domains",
    component: lazy(() => import("./packages/brain-domains")),
  },
  {
    path: "/tools/export",
    name: "lead-export",
    exact: true,
    menu: "tools",
    isPrivate: true,
    iconType: "usersRolesApp",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.CLIENT]: true,
    },
    label: "Export lead",
    title: "Export lead",
    subtitle: "Create and store an export lead",
    component: lazy(() => import("./packages/brain-tools/pages/export")),
  },
  {
    path: "/tools/tester",
    name: "tester",
    exact: true,
    menu: "tools",
    isPrivate: true,
    iconType: "usersRolesApp",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
    },
    label: "Integration test",
    title: "Integration test",
    subtitle: "Test campaign integration",
    component: lazy(() =>
      import("./packages/brain-tools/pages/integration-test"),
    ),
  },
  {
    path: "/organizations/create",
    name: "create-organization",
    exact: true,
    isPrivate: true,
    iconType: "accessibility",
    roles: {
      [userRoles.SUPERADMIN]: true,
    },
    label: "Create organization",
    title: "Create organization",
    subtitle: "Create organization",
    component: lazy(() =>
      import("./packages/brain-settings/pages/organizations/components/create"),
    ),
  },
  {
    path: "/organizations/:id",
    name: "edit-user",
    exact: false,
    isPrivate: true,
    label: "Edit user",
    roles: {
      [userRoles.SUPERADMIN]: true,
    },
    title: "Edit user",
    subtitle: "Edit  user",
    component: lazy(() =>
      import("./packages/brain-settings/pages/organizations/components/edit"),
    ),
  },

  {
    path: "/organizations",
    name: "organizations",
    exact: true,
    menu: "settings",
    isPrivate: true,
    iconType: "metricsApp",
    roles: {
      [userRoles.SUPERADMIN]: true,
    },
    label: "Organizations",
    title: "Organizations",
    subtitle: "Manage organizations",
    component: lazy(() =>
      import("./packages/brain-settings/pages/organizations"),
    ),
  },
  {
    path: "/users/create",
    name: "create-user",
    exact: true,
    isPrivate: true,
    iconType: "accessibility",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
    },
    label: "Create user",
    title: "Create user",
    subtitle: "Create user",
    component: lazy(() =>
      import("./packages/brain-settings/pages/users/components/create"),
    ),
  },
  {
    path: "/users/:id",
    name: "edit-user",
    exact: false,
    isPrivate: true,
    label: "Edit user",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
    },
    title: "Edit user",
    subtitle: "Edit  user",
    component: lazy(() =>
      import("./packages/brain-settings/pages/users/components/edit"),
    ),
  },

  {
    path: "/users",
    name: "users",
    exact: true,
    menu: "settings",
    isPrivate: true,
    iconType: "metricsApp",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
    },
    label: "Users",
    title: "Users",
    subtitle: "Manage users",
    component: lazy(() => import("./packages/brain-settings/pages/users")),
  },
  {
    path: "/wiki/create",
    name: "create-wiki",
    exact: true,
    menu: "wiki",
    isPrivate: true,
    iconType: "accessibility",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Create",
    title: "Create wiki",
    subtitle: "Create wiki post",
    component: lazy(() => import("./packages/brain-wiki/pages/create")),
  },
  {
    path: "/wiki/:id/edit",
    name: "edit-wiki",
    exact: false,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Edit wiki",
    title: "Edit wiki",
    subtitle: "Edit wiki",
    component: lazy(() => import("./packages/brain-wiki/pages/edit")),
  },
  {
    path: "/wiki/:slug",
    name: "show-wiki",
    exact: false,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Wiki post",
    title: "Wiki post",
    subtitle: "Show wiki post",
    component: lazy(() => import("./packages/brain-wiki/pages/show")),
  },
  {
    path: "/wiki",
    name: "wiki",
    exact: true,
    menu: "wiki",
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "metricsApp",
    label: "List",
    title: "Wiki",
    subtitle: "Manage wiki post",
    component: lazy(() => import("./packages/brain-wiki/pages")),
  },
  {
    path: "/flamel/plannings/:id/details",
    name: "flamel details plannings",
    exact: false,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "metricsApp",
    label: "Plannings details",
    title: "Plannings details",
    subtitle: "Flamel plannings details",
    component: lazy(() =>
      import("./packages/brain-flamel/pages/plannings/components/details"),
    ),
  },
  {
    path: "/flamel/plannings/:id",
    name: "flamel edit plannings",
    exact: false,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "metricsApp",
    label: "Planning edit",
    title: "Planning edit",
    subtitle: "Flamel plannings edit",
    component: lazy(() =>
      import("./packages/brain-flamel/pages/plannings/edit"),
    ),
  },
  {
    path: "/flamel/plannings",
    name: "flamel plannings",
    exact: true,
    menu: "flamel",
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "metricsApp",
    label: "Plannings",
    title: "Plannings",
    subtitle: "Flamel plannings",
    component: lazy(() => import("./packages/brain-flamel/pages/plannings")),
  },
  {
    path: "/flamel/stats",
    name: "flamel stats",
    exact: true,
    menu: "flamel",
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "metricsApp",
    label: "Stats",
    title: "Planning stats",
    subtitle: "Flamel stats",
    component: lazy(() => import("./packages/brain-flamel/pages/stats")),
  },
  {
    path: "/geo/audiences",
    name: "geo audiences",
    exact: true,
    menu: "geo-audiences",
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "metricsApp",
    label: "Geo audiences",
    title: "Geo audiences",
    subtitle: "Geo audiences",
    component: lazy(() => import("./packages/brain-geo/pages/audiences")),
  },
  {
    path: "/geo/audiences/create",
    name: "geo audiences create",
    exact: true,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "metricsApp",
    label: "Geo audiences",
    title: "Create Geo audience",
    subtitle: "Geo audiences",
    component: lazy(() =>
      import("./packages/brain-geo/pages/audiences/create"),
    ),
  },
  {
    path: "/geo/audiences/:id",
    name: "geo audiences edit",
    exact: true,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    iconType: "metricsApp",
    label: "Geo audiences",
    title: "Edit Geo audience",
    subtitle: "Geo audiences",
    component: lazy(() => import("./packages/brain-geo/pages/audiences/edit")),
  },
  {
    path: "/public/creative-groups/:id",
    name: "public-creative-groups",
    isPrivate: false,
    menu: false,
    iconType: "metricsApp",
    label: "Creative groups",
    title: "Creative groups",
    subtitle: "Manage creative groups",
    component: () => <CreativeGroupPage />,
  },
  {
    path: "/public/creative-groups",
    name: "public-creative-groups",
    isPrivate: false,
    menu: false,
    iconType: "metricsApp",
    label: "Creative groups",
    title: "Creative groups",
    subtitle: "Manage creative groups",
    component: () => <CreativeGroupPage />,
  },
  {
    path: "/public/lead-campaigns/:id",
    name: "public-lead-campaigns",
    isPrivate: false,
    menu: false,
    iconType: "metricsApp",
    label: "Lead campaign",
    title: "Lead campaign",
    subtitle: "Manage lead campaign",
    component: () => <LeadCampaignPage />,
  },
  {
    path: "/public/clients/:id",
    name: "public-clients",
    isPrivate: false,
    menu: false,
    iconType: "metricsApp",
    label: "Clients",
    title: "Clients",
    subtitle: "Manage clients",
    component: () => <ClientPage />,
  },
  {
    path: "/public/clients",
    name: "public-clients",
    isPrivate: false,
    menu: false,
    iconType: "metricsApp",
    label: "Clients",
    title: "Clients",
    subtitle: "Manage clients",
    component: () => <ClientPage />,
  },
  {
    path: "/login",
    name: "login",
    exact: true,
    menu: false,
    isPrivate: false,
    iconType: "usersRolesApp",
    label: "Login",
    title: "Login",
    subtitle: "Login to dashboard",
    component: () => <LoginPage />,
  },
  {
    path: "/password/update",
    name: "update-password",
    exact: true,
    menu: false,
    isPrivate: false,
    iconType: "usersRolesApp",
    label: "Update password",
    title: "Update password",
    subtitle: "Login to dashboard",
    component: () => <UpdatePasswordPage />,
  },
  {
    path: "/unauthorized",
    name: "unauthorized",
    exact: true,
    menu: false,
    isPrivate: false,
    iconType: "usersRolesApp",
    label: "Unauthorized page",
    title: "Unauthorized page",
    subtitle: "Unauthorized page",
    component: () => <UnauthorizedPage />,
  },
  {
    path: "/profile",
    name: "User profile",
    exact: true,
    isPrivate: true,
    iconType: "accessibility",
    label: "Profile",
    title: "User profile",
    subtitle: "User profile",
    component: () => <ProfilePage />,
  },
  // tiktok
  {
    path: "/tiktok/accounts",
    name: "tiktok-accounts",
    exact: true,
    menu: "tiktok",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    isPrivate: true,
    label: "Ad Accounts",
    title: "Tiktok accounts",
    subtitle: "Tiktok account list",
    component: lazy(() => import("./packages/brain-tiktok/pages/ad-accounts")),
  },
  {
    path: "/tiktok/campaigns",
    name: "tiktok-campaigns",
    exact: true,
    isPrivate: true,
    menu: "tiktok",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Campaigns",
    title: "Tiktok campaigns",
    subtitle: "Tiktok campaigns",
    component: lazy(() => import("./packages/brain-tiktok/pages/campaigns")),
  },
  {
    path: "/tiktok/instant-forms",
    name: "tiktok-instant-forms",
    exact: true,
    menu: "tiktok",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    isPrivate: true,
    label: "Instant Forms",
    title: "TikTok Instant Forms",
    subtitle: "TikTok Instant Forms",
    component: lazy(() =>
      import("./packages/brain-tiktok/pages/instant-forms/instant-forms-list"),
    ),
  },
  {
    path: "/tiktok/instant-forms/create",
    name: "tiktok-instant-forms-create",
    exact: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    isPrivate: true,
    label: "Instant Forms",
    title: "TikTok Instant Forms",
    subtitle: "TikTok Instant Forms",
    component: lazy(() =>
      import(
        "./packages/brain-tiktok/pages/instant-forms/instant-forms-create"
      ),
    ),
  },
  {
    path: "/tiktok/instant-forms/:id",
    name: "tiktok-instant-forms-edit",
    headerUrl: "/tiktok/instant-forms",
    exact: true,
    isPrivate: true,
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Instant Forms",
    title: "TikTok Instant Forms",
    subtitle: "TikTok Instant Forms",
    component: lazy(() =>
      import("./packages/brain-tiktok/pages/instant-forms/instant-forms-edit"),
    ),
  },
  {
    path: "/tiktok/traffic-sources",
    name: "tiktok-traffic-sources",
    exact: true,
    isPrivate: true,
    menu: "tiktok",
    roles: {
      [userRoles.SUPERADMIN]: true,
      [userRoles.ADMIN]: true,
      [userRoles.FULL]: true,
      [userRoles.MARKETING]: true,
    },
    label: "Traffic sources",
    title: "Tiktok traffic sources",
    subtitle: "Tiktok traffic sources",
    component: lazy(() =>
      import("./packages/brain-tiktok/pages/traffic-sources"),
    ),
  },
];
